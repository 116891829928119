import React, { useEffect, useState } from "react";
import API from '../../Visa/API'
import { useNavigate, useParams } from 'react-router-dom';
import { Navbar, Nav, Container } from "react-bootstrap";
const GroupsNavbar = () => {
  const { GroupTourId } = useParams();
  const [groupTourName, setGroupTourName] = useState('');
  const [groupTourCount, setGroupTourCount] = useState('');
  const navigate = useNavigate();

  async function fetchGroupTourName () {
    try {
      const getGroupTourName = await API.get(`groups/groupTourName/${GroupTourId}`);
      setGroupTourName(getGroupTourName.data.GroupTourName);
      setGroupTourCount(getGroupTourName.data.GroupActiveCount);
    } catch (error) {
      console.error(error)
    }    
  }  
  useEffect(() => {
    fetchGroupTourName();
  }, [])  

  return (
    <>
      <Navbar bg="dark" expand="lg" variant="dark">
        <Container>
          {/* Left: Logo */}
          <Navbar.Brand href="/" className="me-auto">
            <img
              src="https://marcopolo-holidays.com/wp-content/uploads/2021/08/Marcopolo-Grey-transparent-logo-200-px.png"
              width="120px"
              alt="Logo"
            />
          </Navbar.Brand>

          {/* Center: Group Tour Name */}
          <Navbar.Text className="mx-auto" style={{ color: 'white', fontSize: '20px', textAlign: 'center', cursor: 'pointer' }}
            onClick={()=>navigate(`/groups/${GroupTourId}`)}
          >
            {groupTourName} <small>({groupTourCount} Active Participants)</small>
          </Navbar.Text>

          {/* Right: Navigation Links */}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav>
              <a href="/groups" style={{ margin: "10px", color: 'white' }}>
                Groups
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <style>
        {`
            .me-auhref a {
                color: white;
                text-decoration: none;
            }
            .me-auhref a:hover {
                color: grey;
            }`
        }
      </style>
    </>
  );
};

export default GroupsNavbar;
