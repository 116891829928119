import { useEffect, useState } from "react";
import { Button, FormControlLabel, Typography  } from '@mui/material'
import API from "../../Visa/API";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import GroupTable from "../components/groupTable";
import Checkbox from '@mui/material/Checkbox';


const GroupDashboard = () => {

    const { GroupTourId } = useParams();

    const [groupData, setGroupData] = useState({});
    const [loading, setLoading] = useState(true);
    const [isGroupAdmin, setIsGroupAdmin] = useState(false);
    const [singularFilter, setSingularFilter] = useState(null);

    const fetchGroup = async () => {
        try {
            toast.loading("Loading Groups")
            const response = await API.get(`/groups/completeGroupData/${GroupTourId}?filter=${singularFilter}`);
            setGroupData(response.data.groupData);
            toast.dismiss();
            toast.success("Loaded successfully");
        } catch (error) {
            console.error("Failed to load group");
            toast.dismiss();
            toast.error("Failed to load")
        } finally {
            setLoading(false)
        }    
    }

    const getStaffRole = async() => {
        const response = await API.get('/auth/getUserRole')
        if (response.data.staff_department?.includes("groupsAdmin")){
            setIsGroupAdmin(true);
        }
    }

    useEffect(()=>{
        fetchGroup();
        getStaffRole();
    }, [singularFilter]);

    const generateDocument = async (exportType) => {
        try {
          const response = await API.get(`groups/completeGroupData/${groupData._id}/export/${exportType}`, {
            responseType: 'blob', // Ensure we receive the data as a Blob
          });
          // Create a Blob from the response data
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          
          // Create a link element
          const link = document.createElement('a');
          
          // Create a URL for the Blob and set it as the href
          link.href = window.URL.createObjectURL(blob);
          
          // Set the download attribute with a filename
          if (['visaRequiredList'].includes(exportType)){
            link.download = `${groupData.tourName}-${exportType}.docx`;  
          } else {
            link.download = `${groupData.tourName}-${exportType}.xlsx`;
          }
          // Append the link to the document body and trigger a click to download the file
          document.body.appendChild(link);
          link.click();
          
          // Clean up by removing the link after download
          document.body.removeChild(link);
        } catch (error) {
          console.error('Error downloading the file', error);
        }
      };

    const handleFilterChange = (e, filter) => {
        if (filter === "ShowViolations" && e.target.checked) {
            setSingularFilter("ShowViolations")
        } else if (filter === "ShowUnverified" && e.target.checked) {
            setSingularFilter("ShowUnverified")
        } else {
            setSingularFilter(null)
        }
    }

    return (
        <>        
            {GroupTourId !== 'all' && (
                <div className="container d-flex">
                    <div className="row">
                        <div className="col-12 mt-3 mb-1">
                            <Typography variant="h6" >QUICK EXPORT OPTIONS</Typography> 
                        </div>
                        <div className="col-12 mb-3">
                        {isGroupAdmin && (
                            <>
                            <Button
                                variant="outlined"
                                onClick={() => (generateDocument('passportList'))}
                                className="ms-2 text-start custom-button" // Adds spacing and keeps text alignment
                            >
                                Passport List 
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => (generateDocument('universityLetter'))}
                                className="ms-2 text-start custom-button" // Adds spacing and keeps text alignment
                            >
                                University Letter 
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => (generateDocument('travelInsurance'))}
                                className="ms-2 text-start custom-button" // Adds spacing and keeps text alignment
                            >
                                Travel Insurance
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => (generateDocument('airlineReservation'))}
                                className="ms-2 text-start custom-button" // Adds spacing and keeps text alignment
                            >
                                Airline Reservation
                            </Button>    
                            <Button
                                variant="outlined"
                                onClick={() => (generateDocument('visaRequiredList'))}
                                className="ms-2 text-start" // Adds spacing and keeps text alignment
                            >
                                Visa Required List
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => (generateDocument('contactList'))}
                                className="ms-2 text-start" // Adds spacing and keeps text alignment
                            >
                                Contact List
                            </Button>                        
                            </>
                        )}
                        </div>
                        <div className="col-12">
                            <FormControlLabel control={<Checkbox checked={singularFilter === "ShowViolations"} onChange={(e) => (handleFilterChange(e, "ShowViolations"))} />} label="Show Violations" />
                            <FormControlLabel control={<Checkbox checked={singularFilter === "ShowUnverified"} onChange={(e) => (handleFilterChange(e, "ShowUnverified"))} />} label="Show Unverified" />
                        </div>
                    </div>
                </div>

            )}
            {!loading && (<GroupTable
                primaryDestination={groupData.PrimaryDestination}
                fetchGroup={fetchGroup}
                participantData={groupData.participants} 
                GroupTourId={GroupTourId} 
                isGroupAdmin={isGroupAdmin} 
                tourDuration={groupData.tourDuration} 
                visaApplicationDate={groupData.visaApplicationDate}
                tourName={groupData.tourName}
                />
            )}
        </>
    );
}

export default GroupDashboard