import React, { useState, useEffect, useRef } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  FormControl,
  Button,
  Checkbox,
  InputLabel,
  FormControlLabel,
  Grid,
  MenuItem,
  Select
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import API from "../../Visa/API";
import 'dayjs/locale/en-gb';
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const ParticipantForm = ({ primaryDestination, fetchGroup, open, handleClose, participantData, handleSubmit, GroupTourId, tourName }) => {
  
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (typeof formData.visaURL === "string" && formData.visaURL.trim() &&
        typeof formData.visaFile === "string" && formData.visaFile.trim()) {
      onSubmit();
      fetchGroup();
    }
  }, [formData.visaURL, formData.visaFile]);

  useEffect(() => {
    setFormData({
      "schengenVisa": {
          "obtained": participantData?.obtained
      },
      "uaeResidenceVisa": {
          "stampedInPassport": participantData?.uaeResidenceVisa?.stampedInPassport,
          "emirateOfIssue": participantData?.uaeResidenceVisa?.emirateOfIssue,
          "dateOfExpiry": participantData?.uaeResidenceVisa?.dateOfExpiry,
          "uaeResidenceVisaURL": participantData?.uaeResidenceVisa?.uaeResidenceVisaURL,
          "profession": participantData?.uaeResidenceVisa?.profession
      },
      "preferences": {
          "privateRoomRequest": {
              "requested": participantData?.preferences?.privateRoomRequest?.requested,
              "additionalCost": participantData?.preferences?.privateRoomRequest?.additionalCost,
              "termsAccepted": participantData?.preferences?.privateRoomRequest?.termsAccepted,
          },
          "foodMedicalAllergies": participantData?.preferences?.foodMedicalAllergies,
          "ongoingHealthConditions": participantData?.preferences?.ongoingHealthConditions,
          "others": participantData?.preferences?.others
      },
      "typeformURLs": {
          "passportURL": participantData?.typeformURLs?.passportURL,
          "uaeResidenceVisaURL": participantData?.typeformURLs?.uaeResidenceVisaURL,
          "emiratesIdURL": participantData?.typeformURLs?.emiratesIdURL
      },
      "fullName": participantData?.fullName,
      "firstName": participantData?.firstName,
      "surName": participantData?.surName,
      "gender": participantData?.gender,
      "dateOfBirth": participantData?.dateOfBirth,
      "nationality": participantData?.nationality,
      "isUAEMentionedUAENat": participantData?.isUAEMentionedUAENat,
      "travelDocumentType": participantData?.travelDocumentType,
      "passportExpiryDate": participantData?.passportExpiryDate,
      "passportNumber": participantData?.passportNumber,
      "mobileNumber": participantData?.mobileNumber,
      "whatsappNumber": participantData?.whatsappNumber,
      "personalEmail": participantData?.personalEmail,
      "secondaryEmail": participantData?.secondaryEmail,
      "residentialAddress": participantData?.residentialAddress,
      "emirateOfResidence": participantData?.emirateOfResidence,
      "emergencyContacts": participantData?.emergencyContacts,
      "violations": {
          "isVisaRequired": participantData?.violations?.isVisaRequired,
          "isUAEVisaViolated": participantData?.violations?.isUAEVisaViolated,
          "isPassportViolated": participantData?.violations?.isPassportViolated,
          "isMinorDurVisaApp": participantData?.violations?.isMinorDurVisaApp
      },
      "isVerified": participantData?.isVerified,
      "isVerifiedScore": participantData?.isVerifiedScore,
      "visaApplicationDate": participantData?.visaApplicationDate,
      "visaURL": participantData?.visaURL || '',
      "visaFile": participantData?.visaFile || '',
  })
  }, [participantData])

  // // Handle input change
  // const handleChange = (e) => {
  //   const { name, value, type, checked } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: type === 'checkbox' ? checked : value,
  //   });
  // };
  


  const handleRemoveFile = () => {
    setFormData({
      ...formData,
      visaURL: '',
      visaFile: '',
    })
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Get the selected file
    if (!file) {
      console.error("File Not Found") // Store the file in state
    }
    handleUploadFile(file);
  }

  const handleUploadFile = (file) => {
    // Create a FileReader to read the file as an ArrayBuffer
    const reader = new FileReader();
    reader.onload = async (event) => {
      const fileContent = event.target.result.split(',')[1];
      const arrayBuffer = event.target.result; // The ArrayBuffer of the file
  
      // Convert the buffer to a base64 string (if needed)
      const buffer = new Uint8Array(arrayBuffer);
      const bufferString = Array.from(buffer)?.map(byte => String.fromCharCode(byte)).join('');
  
      // Get file data based on document type
      const selectedFileData = { name: `${primaryDestination} visa`, key: 'visaFile', urlKey: 'visaURL' };
      toast.loading(`Uploading File ${selectedFileData.name}`)
      if (selectedFileData === null) {
        console.error("File Name Error");
        return;
      }
      // Prepare the payload
      const payload = {
        fileName: selectedFileData,
        fileExtension: file.name.split('.').pop(),
        tourId: GroupTourId,
        tourName: tourName,
        participantFullName: participantData.fullName,
        fileType: file.type,
        buffer: fileContent, // Include the ArrayBuffer in your request
      };
      try {
        // Make the POST request using Axios
        const response = await API.post('/groups/uploadParticipantAttachment', payload);
        console.log(response)
        if (response.status === 200) {
          toast.dismiss();
          toast.success("Uploaded");
          fileInputRef.current.value = ''; 
          setFormData({
            ...formData,
            visaURL: response.data.fileURL,
            visaFile: response.data.file_path,
          })
        } else {
          console.error("File upload failed");
          toast.dismiss();
          toast.error("Upload Error");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        toast.dismiss();
        toast.error("Upload Error");
      } 
    };
  
    // Read the file as an ArrayBuffer
    if (file !== null) {
      reader.readAsDataURL(file);
    }
  };  

  function formatDate(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const month = monthNames[d.getMonth()];
    const year = String(d.getFullYear()).slice(-2); // Last two digits
    return `${day}-${month}-${year}`;
  }  

  const handleParticipantCancel = async () => {
    try {
      const tour = await API.put('/groups/cancelParticipant', {
        tourId: GroupTourId, fullName: participantData?.fullName, cancelStatus: 'true', cancelRemark: `01-11-24 - Cancelled due to visa rejection`
      });
      fetchGroup();
    } catch (error) {
      console.error(error);
    }
  } 

  // Form submission
  const onSubmit = async () => {
    const tour = await API.put('/groups/updateParticipant', {
      tourId: GroupTourId, fullName: participantData?.fullName, participantData: formData
    });
    fetchGroup();
  };
  return (
    <>
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90vw',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          maxHeight: '90vh', 
          overflowY: 'auto', 
        }}
      >
        <Grid container>
          <Grid item md={8}>        
            <Typography variant="h6" component="h2" gutterBottom>
              Participant Details
            </Typography>
          </Grid>
          <Grid item md={4}>        
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              sx={{ m: 2 }}
            >
              Close
            </Button>
            {GroupTourId==='all' && (
              <Button
                  variant="contained"
                  color="secondary"
                  onClick={(e) => {window.location.href =`/groups/${participantData.GroupTourId}?participant=${participantData.fullName}`}}
                  sx={{ m: 2 }}
              >
                Go To Profile
              </Button>
            )}
            {GroupTourId!=='all' && (
              <Button
                  variant="contained"
                  color="secondary"
                  onClick={(e) => {navigate(`/group-verification/${GroupTourId}?participant=${participantData.fullName}&documentType=Passport`)}}
                  sx={{ m: 2 }}
              >
                Go To Verification
              </Button>
            )}
          </Grid>
          {participantData?.violations?.isVisaRequired && (
            <>
          <Grid item md={12} sx={{ mb: 2 }}>    
            <Box display="flex" alignItems="center">    
              <Typography variant="p" component="p" gutterBottom>
                <strong>{primaryDestination} Visa Application Details</strong>
              </Typography>
              {(typeof formData.visaURL !== 'string' || formData.visaURL.trim() === '') && (
                <>
                <input type="file" style={{display:'none'}} ref={fileInputRef} onChange={handleFileChange} />

                <Button
                  variant="outlined"
                  startIcon={
                    <img 
                      className="mx-1" 
                      src={`${process.env.PUBLIC_URL}/globe.svg`} 
                      alt="Passport Control" 
                      style={{ width: '20px', height: '20px' }}
                    />
                  }
                  style={{ marginLeft: 8, color: 'green', borderColor: 'green' }} 
                  onClick={() => fileInputRef.current.click()} // Trigger file selection
                  >
                  Upload Approved Visa
                </Button>
                <Button
                  variant="outlined"
                  startIcon={
                    <img 
                      className="mx-1" 
                      src={`${process.env.PUBLIC_URL}/cancel.svg`} 
                      alt="Passport Control" 
                      style={{ width: '20px', height: '20px' }}
                    />
                  }
                  style={{ marginLeft: 8, color: '#DD2E18', borderColor: '#DD2E18' }} 
                  onClick={() => {handleParticipantCancel()}}
                >
                  Visa Rejected
                </Button>                
                </>
              )}
              {(typeof formData.visaURL === 'string' && formData?.visaURL?.trim().startsWith("https://marcopoloholidays151.sharepoint.com/")) && (
                <>
                <Button
                  variant="outlined"
                  startIcon={
                    <img 
                      className="mx-1" 
                      src={`${process.env.PUBLIC_URL}/globe.svg`} 
                      alt="Global Visa" 
                      style={{ width: '20px', height: '20px' }}
                    />
                  }
                  style={{ marginLeft: 8 }} 
                  onClick={() => window.open(participantData?.visaURL,'_blank', 'width=1000,height=1000,menubar=no,toolbar=no,resizable=yes')} // Trigger file selection
                  >
                    Show {primaryDestination} Visa
                </Button>
                <Button
                  variant="contained"
                  style={{ marginLeft: 8, color: 'white', borderColor: '#DD2E18', backgroundColor: '#DD2E18' }} 
                  onClick={() => {handleRemoveFile()}}
                >
                  Remove
                </Button>                
                </>
              )}              
            </Box>
          </Grid>
          <Grid container sx={{ mb: 2 }} style={{alignItems: 'center'}}>
            <Grid item md={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                  <DatePicker                                        
                      label='Visa Application Date'
                      value={participantData?.visaApplicationDate ? dayjs(participantData.visaApplicationDate) : null}
                      onChange={(date) => setFormData({ ...formData, visaApplicationDate: date.toISOString()})}
                      helperText='DD/MM/YYYY'
                      renderInput={(params) => (
                      <TextField                                             
                          fullWidth  // Ensure fullWidth is passed
                          variant='standard'  // Ensure variant is standard
                          sx={{ width: '100%' }}  // Ensure the component uses 100% width
                          placeholder='DD/MM/YYYY'
                          className='p-2'
                      />
                      )}
                  />
              </LocalizationProvider>
            </Grid>
          </Grid>
          </>
          )}
          <Grid item md={12}>
            <Box display="flex" alignItems="center">
              <Typography variant="body1" component="p" gutterBottom style={{margin: '0'}}>
                <strong>Passport Details</strong>
              </Typography>
              <Button
                variant="outlined"
                startIcon={
                  <img 
                    className="mx-1" 
                    src={`${process.env.PUBLIC_URL}/passport-control.svg`} 
                    alt="Passport Control" 
                    style={{ width: '20px', height: '20px' }}
                  />
                }
                style={{ marginLeft: 8 }}
                onClick={() => {
                  window.open(participantData?.typeformURLs?.passportURL,'_blank', 'width=1000,height=1000,menubar=no,toolbar=no,resizable=yes');
                }}
              >
                Show Passport
              </Button>
            </Box>
          </Grid>
          <Grid item md={4}>
            <TextField
              label={`Full Name ${participantData?.isVerified?.passportData?.fullName ? '✓' : '✗'}`}
              name="fullName"
              value={formData.fullName}
              fullWidth
              margin="normal"
              disabled
              variant='outlined'
              className='p-2 noeditfield'
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label={`First Name ${participantData?.isVerified?.passportData?.firstName ? '✓' : '✗'}`}              
              name="firstName"
              value={formData.firstName}
              fullWidth
              margin="normal"
              disabled
              variant='outlined'
              className='p-2 noeditfield'
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label={`Surname ${participantData?.isVerified?.passportData?.surName ? '✓' : '✗'}`}              
              name="surname"
              value={formData.surName}
              fullWidth
              margin="normal"
              disabled
              variant='outlined'
              className='p-2 noeditfield'
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label={`Gender ${participantData?.isVerified?.passportData?.gender ? '✓' : '✗'}`}              
              name="gender"
              value={formData.gender}
              fullWidth
              margin="normal"
              disabled
              variant='outlined'
              className='p-2 noeditfield'
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label={`Date Of Birth ${participantData?.isVerified?.passportData?.dateOfBirth ? '✓' : '✗'}`}              
              name="dateOfBirth"
              value={formatDate(formData.dateOfBirth)}
              fullWidth
              margin="normal"
              disabled
              variant='outlined'
              className='p-2 noeditfield'
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label={`Nationality ${participantData?.isVerified?.passportData?.nationality ? '✓' : '✗'}`}              
              name="nationality"
              value={formData.nationality}
              fullWidth
              margin="normal"
              disabled
              variant='outlined'
              className='p-2 noeditfield'
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label={`Travel Document Type ${participantData?.isVerified?.passportData?.travelDocumentType ? '✓' : '✗'}`}              
              name="travelDocumentType"
              value={formData?.travelDocumentType}
              fullWidth
              margin="normal"
              disabled
              variant='outlined'
              className='p-2 noeditfield'
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label={`Passport Expiry ${(participantData?.isVerified?.passportData?.nationality && !participantData?.violations?.isPassportViolated) ? '✓' : '✗'}`}              
              name="passportExpiryDate"
              value={formatDate(formData.passportExpiryDate)}
              fullWidth
              margin="normal"
              disabled
              variant='outlined'
              className='p-2 noeditfield'
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label={`${participantData?.travelDocumentType} Number ${participantData?.isVerified?.passportData?.passportNumber ? '✓' : '✗'}`}              
              name="passportNumber"
              value={formData.passportNumber}
              fullWidth
              margin="normal"
              disabled
              variant='outlined'
              className='p-2 noeditfield'
            />
          </Grid>          
          {formData.nationality==="United Arab Emirates" && (
            <Grid item md={12}>
              <TextField
                label={`Is UAE Mentioned in Passport ${participantData?.isVerified?.passportData?.isUAEMentionedUAENat ? '✓' : '✗'}`}              
                name="isUAEMentionedUAENat"
                value={formData.isUAEMentionedUAENat?formData.isUAEMentionedUAENat:''}
                fullWidth
                margin="normal"
                disabled
                variant='standard'
                className='p-2 noeditfield'
              />
            </Grid>            
          )}
          <Grid item md={12}>
            <Box display="flex" alignItems="center">
              <Typography variant="body1" component="p" gutterBottom style={{margin: '0'}}>
                <strong>UAE Visa Details</strong>
              </Typography>
              <Button
                variant="outlined"
                startIcon={
                  <img 
                    className="mx-1" 
                    src={`${process.env.PUBLIC_URL}/united-arab-emirates.svg`} 
                    alt="UAE Visa placeholder" 
                    style={{ width: '20px', height: '20px' }}
                  />
                }
                style={{ marginLeft: 8 }}
                onClick={() => {
                  window.open(participantData?.typeformURLs?.uaeResidenceVisaURL,'_blank', 'width=1000,height=1000,menubar=no,toolbar=no,resizable=yes');
                }}
              >
                Show UAE VISA
              </Button>
              <Button
                variant="outlined"
                startIcon={
                  <img 
                    className="mx-1" 
                    src={`${process.env.PUBLIC_URL}/id-card.svg`} 
                    alt="UAE Emirates ID placeholder" 
                    style={{ width: '20px', height: '20px' }}
                  />
                }
                style={{ marginLeft: 8 }}
                onClick={() => {
                  window.open(participantData?.typeformURLs?.emiratesIdURL,'_blank', 'width=1000,height=1000,menubar=no,toolbar=no,resizable=yes');
                }}
              >
                Show Emirates ID
              </Button>              
            </Box>
          </Grid>          
           <Grid item md={3}>
            <TextField
              label={`UAE Residence Visa Emirate Of Issue ${participantData?.isVerified?.uaeResidenceVisaData?.emirateOfIssue  ? '✓' : '✗'}`}              
              name="emirateOfIssue"
              value={formData?.uaeResidenceVisa?.emirateOfIssue}
              fullWidth
              margin="normal"
              variant='outlined'
              disabled
              className='p-2 noeditfield'
            />
          </Grid>
           <Grid item md={3}>
            <TextField
              label={`UAE Residence Visa Expiry ${participantData?.isVerified?.uaeResidenceVisaData?.dateOfExpiry && !participantData?.violations?.isUAEVisaViolated ? '✓' : '✗'}`}               
              name="dateOfExpiry"
              value={formatDate(formData?.uaeResidenceVisa?.dateOfExpiry)}
              fullWidth
              margin="normal"
              variant='outlined'
              disabled
              className='p-2 noeditfield'
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              label={`UAE Residence Visa linked to/stamped in the passport used to travel ${participantData?.isVerified?.uaeResidenceVisaData?.stampedInPassport ? '✓' : '✗'}`}                        
              name="stampedInPassport"
              value={formData?.uaeResidenceVisa?.stampedInPassport?'Yes':'No'}
              fullWidth
              margin="normal"
              variant='outlined'
              disabled
              className='p-2 noeditfield'
            />
          </Grid> 
          <Grid item md={3}>
            <TextField
              label={`Profession ${participantData?.isVerified?.uaeResidenceVisaData?.profession ? '✓' : '✗'}`}
              name="profession"
              value={formData?.uaeResidenceVisa?.profession}
              fullWidth
              margin="normal"
              variant='outlined'
              disabled
              className='p-2 noeditfield'
            />
          </Grid>          
          <Grid item md={12}>        
            <Typography variant="p" component="p" gutterBottom>
              <strong>Contact Details</strong>
            </Typography>
          </Grid>
          <Grid item md={6}>
            <TextField
              label={`Mobile Number ${participantData?.isVerified?.contactInformation?.mobileNumber ? '✓' : '✗'}`}              
              name="mobileNumber"
              value={formData.mobileNumber}
              fullWidth
              margin="normal"
              disabled
              variant='outlined'
              className='p-2 noeditfield'
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              label={`Whatsapp Number ${participantData?.isVerified?.contactInformation?.whatsappNumber ? '✓' : '✗'}`}              
              name="whatsappNumber"
              value={formData.whatsappNumber}
              fullWidth
              margin="normal"
              disabled
              variant='outlined'
              className='p-2 noeditfield'
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              label={`Personal Email ${participantData?.isVerified?.contactInformation?.personalEmail ? '✓' : '✗'}`}              
              name="personalEmail"
              value={formData.personalEmail}
              fullWidth
              margin="normal"
              disabled
              variant='outlined'
              className='p-2 noeditfield'
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              label={`Secondary Email ${participantData?.isVerified?.contactInformation?.secondaryEmail ? '✓' : '✗'}`}              
              name="secondaryEmail"
              value={formData.secondaryEmail}
              fullWidth
              margin="normal"
              disabled
              variant='outlined'
              className='p-2 noeditfield'
            />
          </Grid>
          <Grid item md={12}>        
            <Typography variant="p" component="p" gutterBottom>
              <strong>Emergency Contact Details</strong>
            </Typography>
          </Grid>
          <Grid item md={12}>
              {formData?.emergencyContacts?.map((contact, index) => (
                <Grid container key={contact._id} sx={{ mb: 2 }}>
                  <Grid item md={3}>
                    <TextField
                      label={`Emergency Contact ${index + 1} - Phone Number`}
                      name={`emergencyContactPhoneNumber${index}`}
                      value={contact?.phoneNumber}
                      onChange={(e) => setFormData({
                        ...formData,
                        emergencyContacts: formData.emergencyContacts.map((contactItem, i) =>
                          i === index ? { ...contactItem, phoneNumber: e.target.value } : contactItem
                        )
                      })}
                      fullWidth
                      margin="normal"
                      variant="standard"
                      className="p-2"
                    />
                  </Grid>
                  <Grid item md={3}>
                  <TextField
                    label={`Emergency Contact ${index + 1} - Email`}
                    name={`emergencyContactEmail${index}`}
                    value={contact?.email}
                    onChange={(e) => setFormData({
                      ...formData,
                      emergencyContacts: formData.emergencyContacts.map((contactItem, i) =>
                        i === index ? { ...contactItem, email: e.target.value } : contactItem
                      )
                    })}                    
                    fullWidth
                    margin="normal"
                    variant="standard"
                    className="p-2"
                  />
                  </Grid>
                  <Grid item md={3}>
                  <TextField
                    label={`Emergency Contact ${index + 1} - Name`}
                    name={`emergencyContactName${index}`}
                    value={contact?.name || ''} // Handle empty name field
                    onChange={(e) => setFormData({
                      ...formData,
                      emergencyContacts: formData.emergencyContacts.map((contactItem, i) =>
                        i === index ? { ...contactItem, name: e.target.value } : contactItem
                      )
                    })}   
                    fullWidth
                    margin="normal"
                    variant="standard"
                    className="p-2"
                  />
                  </Grid>
                  <Grid item md={3}>
                  <TextField
                    label={`Emergency Contact ${index + 1} - Relationship`}
                    name={`emergencyContactRelationship${index}`}
                    value={contact?.relationship}
                    onChange={(e) => setFormData({
                      ...formData,
                      emergencyContacts: formData.emergencyContacts.map((contactItem, i) =>
                        i === index ? { ...contactItem, relationship: e.target.value } : contactItem
                      )
                    })}                       
                    fullWidth
                    margin="normal"
                    variant="standard"
                    className="p-2"
                  />
                  </Grid>
                </Grid>
              ))}
          </Grid>          
          <Grid item md={12}>        
            <Typography variant="p" component="p" gutterBottom>
              <strong>Other Details</strong>
            </Typography>
          </Grid>
          <Grid item md={3}>
            <TextField
              label={`Emirate Of Residence`}              
              name="emirateOfResidence"
              value={formData?.emirateOfResidence}
              onChange={(e) => setFormData({
                ...formData,
                emirateOfResidence: e.target.value
              })}                     
              fullWidth
              margin="normal"
              variant='standard'
              className='p-2'
            />
          </Grid>
          <Grid item md={9}>
            <TextField
              label={`Residential Address`}              
              name="residentialAddress"
              value={formData.residentialAddress}
              onChange={(e) => setFormData({
                ...formData,
                residentialAddress: e.target.value
              })}               
              fullWidth
              margin="normal"
              variant='standard'
              className='p-2'
            />
          </Grid>
          <Grid item md={3}>
            <FormControl fullWidth margin="normal" variant="standard" className="p-2">
              <InputLabel>Private Room Request</InputLabel>
              <Select
                name="privateRoomRequest"
                value={formData?.preferences?.privateRoomRequest?.termsAccepted === true ? "true" : "false"}
                onChange={(e) => setFormData({
                  ...formData,
                  preferences: {
                    ...formData.preferences,
                    privateRoomRequest: {
                      ...formData.preferences.privateRoomRequest,
                      termsAccepted: e.target.value === "true", // Make sure the value is converted correctly
                      requested: e.target.value === "true"
                    }
                  }
                })}                     
                label="Private Room Request" // This works with InputLabel but is optional in this case
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={3}>
            <TextField
              label={`Food Medical Allergies`}              
              name="foodMedicalAllergies"
              value={formData?.preferences?.foodMedicalAllergies}
              onChange={(e) => setFormData({
                ...formData,
                preferences: {
                  ...formData.preferences,
                  foodMedicalAllergies: e.target.value // Make sure the value is converted correctly
                }
              })}
              fullWidth
              margin="normal"
              variant='standard'
              className='p-2'
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              label={`Ongoing Health Conditions`}              
              name="ongoingHealthConditions"
              value={formData?.preferences?.ongoingHealthConditions}
              onChange={(e) => setFormData({
                ...formData,
                preferences: {
                  ...formData.preferences,
                  ongoingHealthConditions: e.target.value // Make sure the value is converted correctly
                }
              })}              
              fullWidth
              margin="normal"
              variant='standard'
              className='p-2'
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              label={`Others`}              
              name="Others"
              value={formData?.preferences?.others}
              onChange={(e) => setFormData({
                ...formData,
                preferences: {
                  ...formData.preferences,
                  others: e.target.value // Make sure the value is converted correctly
                }
              })}              
              fullWidth
              margin="normal"
              variant='standard'
              className='p-2'
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
        {GroupTourId!=='all' && (
          <>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
            sx={{ m: 2 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onSubmit()}
            sx={{ m: 2 }}
          >
            Submit
          </Button>
          </>
        )}
      </Box>
    </Modal>
    </>
  );
};
export default ParticipantForm;