import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Button,
  Grid,
  Typography,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import 'dayjs/locale/en-gb';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';
import API from '../../Visa/API';
import CountrySelect from '../../Visa/components/CountrySelect';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ClearIcon from '@mui/icons-material/Clear';

const GroupSettings = () => {

    const { GroupTourId } = useParams();

    const [groupTour, setGroupTour] = useState({})

    useEffect(() => {
        fetchGroup()
    }, [])

    async function fetchGroup () {
        if (!GroupTourId) {
            setGroupTour({
                tourName: '',
                PrimaryDestination: '',
                OtherDestinations: [],
                tourDescription: '',
                tourDuration: {
                    tourStartDate: null,
                    tourEndDate: null
                },
                visaApplicationDate: null,
                nonVisaRequiringNationalities: [],
                participants: [],
                tourLeaders: [{
                    leaderName: '',
                    leaderEmail: '',
                    leaderPhone: '',
                    leaderDesignation: '',
                    isLeaderTourParticipant: true
                }],
                violationControls: {
                    visaReqUAEResValidType: 'tourEndDate',
                    visaReqUAEResValidDurationDays: '',
                    visaReqPassportValidType: 'tourEndDate',
                    visaReqPassportValidDurationDays: '',
                    noVisaReqPassportValidType: 'tourEndDate',
                    noVisaReqPassportValidDurationDays: ''
                }                
            })
        } else {
            toast.loading("Loading Tour Details");
            try {
                const groupResponse = await API.get(`/groups/getSettings/${GroupTourId}`)
                setGroupTour(groupResponse.data.data)
                toast.dismiss();
                toast.success(`${groupResponse.data.message}`)
            } catch (e) {
                console.error(e);
                toast.dismiss();
                toast.error("Unable to fetch tour details. Contact IT")
            }    
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        toast.loading("Saving Group Tour")
        try {
            const response = await API.post(`/groups/postSettings/${GroupTourId?GroupTourId:''}`, groupTour)
            toast.dismiss();
            toast.success(`${response.data.message}`)
        } catch (error) {
            console.error(error);
            toast.dismiss();
            toast.error(`Failed to complete action`)
        }

    }

    return (
        <form onSubmit={handleSubmit}>
            <Container className='my-5'>
                <Grid container alignItems="end" justifyContent="center" spacing={2}>
                    <Grid sx={{ marginTop: '2rem' }} item md={12} >
                        <h2 style={{ fontSize: '22px'}}>Group Tour Settings</h2>
                    </Grid>                            
                    <TextField
                        id="tourName"
                        label="Tour Name"
                        placeholder="Enter Tour Name"
                        margin="normal"
                        variant='standard'
                        fullWidth
                        required
                        value={groupTour.tourName}
                        onChange={(e) => setGroupTour({...groupTour, tourName: e.target.value})} 
                    />
                    <TextField
                        id="tourDescription"
                        label="Tour Description"
                        placeholder="Describe this tour"
                        margin="normal"
                        variant='standard'
                        multiline
                        rows={3}
                        fullWidth
                        value={groupTour.tourDescription}
                        onChange={(e) => setGroupTour({...groupTour, tourDescription: e.target.value})} 
                    />
                    <CountrySelect 
                        countrySelectVal={groupTour.PrimaryDestination}
                        setCountrySelectVal={(country) => setGroupTour({...groupTour, PrimaryDestination: country})}
                        fieldLabel="Primary Destination *"
                        variant="standard"
                        sx={{width: '100%', marginTop: '1rem'}}
                    />
                    <CountrySelect 
                        countrySelectVal={groupTour.OtherDestinations}
                        setCountrySelectVal={(labels) => {setGroupTour({...groupTour, OtherDestinations: [...labels]})}}
                        fieldLabel="Other Destinations"
                        multiple
                        variant="standard"
                        sx={{width: '100%', marginTop: '1rem'}}
                    />
                    <CountrySelect 
                        countrySelectVal={groupTour.nonVisaRequiringNationalities}
                        setCountrySelectVal={(labels) => {setGroupTour({...groupTour, nonVisaRequiringNationalities: [...labels]})}}
                        fieldLabel="Visa Exempt Nationalities"
                        multiple
                        variant="standard"
                        sx={{width: '100%', marginTop: '1rem'}}
                    />                            
                    <Grid sx={{marginTop: '1rem'}} item md={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                            <DatePicker                                        
                                label='Tour Start Date *'
                                value={groupTour?.tourDuration?.tourStartDate ? dayjs(groupTour?.tourDuration?.tourStartDate) : null}
                                onChange={(date) => setGroupTour({ ...groupTour, tourDuration: {...groupTour.tourDuration, tourStartDate: date.toISOString()}})}
                                helperText='DD/MM/YYYY'
                                renderInput={(params) => (
                                <TextField                                             
                                    fullWidth  // Ensure fullWidth is passed
                                    variant='standard'  // Ensure variant is standard
                                    sx={{ width: '100%' }}  // Ensure the component uses 100% width
                                    placeholder='DD/MM/YYYY'
                                />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid sx={{marginTop: '1rem'}} item md={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                            <DatePicker
                                required
                                label='Tour End Date *'
                                value={groupTour?.tourDuration?.tourEndDate ? dayjs(groupTour?.tourDuration?.tourEndDate) : null}
                                onChange={(date) => setGroupTour({ ...groupTour, tourDuration: {...groupTour.tourDuration, tourEndDate: date.toISOString()}})}
                                helperText='DD/MM/YYYY'
                                renderInput={(params) => (
                                <TextField 
                                    fullWidth  // Ensure fullWidth is passed
                                    variant='standard'  // Ensure variant is standard
                                    sx={{ width: '100%' }}  // Ensure the component uses 100% width
                                    placeholder='DD/MM/YYYY'
                                />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Tooltip title="This date is used to calculate if the participant would be a minor during visa application">
                        <Grid sx={{marginTop: '1rem'}} item md={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                <DatePicker
                                    required
                                    label='Visa Application Date *'
                                    value={groupTour.visaApplicationDate ? dayjs(groupTour.visaApplicationDate) : null}
                                    onChange={(date) => setGroupTour({ ...groupTour, visaApplicationDate: date.toISOString() })}
                                    helperText='Used to calculate if minor'
                                    renderInput={(params) => (
                                    <TextField 
                                        fullWidth  // Ensure fullWidth is passed
                                        variant='standard'  // Ensure variant is standard
                                        sx={{ width: '100%' }}  // Ensure the component uses 100% width
                                        placeholder='DD/MM/YYYY'
                                        helperText="Used to calculate if minor"
                                    />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Tooltip>
                    <Grid sx={{ marginTop: '2rem' }} item md={12} >
                        <h2 style={{ fontSize: '22px'}}>Tour Leaders&nbsp;&nbsp;&nbsp;<GroupAddIcon
                            onClick={() => {
                                const newLeaders = [...groupTour.tourLeaders, {
                                    leaderName: '',
                                    leaderEmail: '',
                                    leaderPhone: '',
                                    leaderDesignation: '',
                                    isLeaderTourParticipant: true
                                }];
                                setGroupTour({ ...groupTour, tourLeaders: newLeaders });
                                }}
                        /></h2>
                    </Grid>
                    {groupTour?.tourLeaders?.length > 0 ? (
                        groupTour.tourLeaders.map((leader, index) => (
                            <>
                            <Grid sx={{ marginTop: '1rem' }} item md={1} key={index}>
                                <Container style={{padding: '0', display: 'flex', justifyContent: 'end', alignContent: 'center', alignItems: 'start'}}>
                                    <ClearIcon style={{color: 'red'}}
                                        onClick={()=>{
                                            const updatedLeaders = groupTour.tourLeaders.filter((_, leaderIndex) => leaderIndex !== index);
                                            setGroupTour({ ...groupTour, tourLeaders: updatedLeaders });                                                 
                                        }}
                                    />
                                </Container> 
                            </Grid>
                            <Grid sx={{ marginTop: '1rem' }} item md={2} key={index}>
                                <TextField
                                    label="Leader Name"
                                    placeholder="Enter Leader Name"
                                    margin="normal"
                                    variant="standard"
                                    fullWidth
                                    value={leader.leaderName || ''}  // Ensure value is a string, even if undefined
                                    onChange={(e) => {
                                    // Create a copy of the tourLeaders array
                                    const updatedLeaders = [...groupTour.tourLeaders];
                                    // Update the specific leader's name at the current index
                                    updatedLeaders[index] = {
                                        ...leader,
                                        leaderName: e.target.value,
                                    };
                                    // Update the groupTour state with the updated leaders array
                                    setGroupTour({ ...groupTour, tourLeaders: updatedLeaders });
                                    }}
                                />
                            </Grid>
                            <Grid sx={{ marginTop: '1rem' }} item md={2} key={index}>
                                <TextField
                                    label="Leader Email"
                                    placeholder="Enter Leader Email"
                                    margin="normal"
                                    variant="standard"
                                    fullWidth
                                    value={leader.leaderEmail || ''}  // Ensure value is a string, even if undefined
                                    onChange={(e) => {
                                    // Create a copy of the tourLeaders array
                                    const updatedLeaders = [...groupTour.tourLeaders];
                                    // Update the specific leader's name at the current index
                                    updatedLeaders[index] = {
                                        ...leader,
                                        leaderEmail: e.target.value,
                                    };
                                    // Update the groupTour state with the updated leaders array
                                    setGroupTour({ ...groupTour, tourLeaders: updatedLeaders });
                                    }}
                                />
                            </Grid>
                            <Grid sx={{ marginTop: '1rem' }} item md={2} key={index}>
                                <TextField
                                    label="Leader Phone"
                                    placeholder="Enter Leader Phone"
                                    margin="normal"
                                    variant="standard"
                                    fullWidth
                                    value={leader.leaderPhone || ''}  // Ensure value is a string, even if undefined
                                    onChange={(e) => {
                                    // Create a copy of the tourLeaders array
                                    const updatedLeaders = [...groupTour.leaderPhone];
                                    // Update the specific leader's name at the current index
                                    updatedLeaders[index] = {
                                        ...leader,
                                        leaderPhone: e.target.value,
                                    };
                                    // Update the groupTour state with the updated leaders array
                                    setGroupTour({ ...groupTour, leaderPhone: updatedLeaders });
                                    }}
                                />
                            </Grid>
                            <Grid sx={{ marginTop: '1rem' }} item md={2} key={index}>
                                <TextField
                                    label="Leader Designation"
                                    placeholder="Enter Leader Designation"
                                    margin="normal"
                                    variant="standard"
                                    fullWidth
                                    value={leader.leaderDesignation || ''}  // Ensure value is a string, even if undefined
                                    onChange={(e) => {
                                    // Create a copy of the tourLeaders array
                                    const updatedLeaders = [...groupTour.leaderDesignation];
                                    // Update the specific leader's name at the current index
                                    updatedLeaders[index] = {
                                        ...leader,
                                        leaderDesignation: e.target.value,
                                    };
                                    // Update the groupTour state with the updated leaders array
                                    setGroupTour({ ...groupTour, tourLeaders: updatedLeaders });
                                    }}
                                />                                                                                                                        
                            </Grid>
                            <Grid sx={{ marginTop: '1rem' }} item md={3} key={index}>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        defaultChecked
                                        sx={{
                                        color: 'grey',
                                        '&.Mui-checked': {
                                            color: 'green',
                                        },
                                        }}
                                    />
                                    }
                                    label="Leader is Tour Participant"  // This is where the label is specified
                                />
                                </Grid>                              
                            </>
                        ))
                        ) : (
                        <p>No tour leaders available</p>  // Handle empty or undefined tourLeaders case
                    )}
                    <Grid sx={{ marginTop: '2rem' }} item md={12} >
                        <h2 style={{ fontSize: '22px'}}>Violation Controls</h2>
                    </Grid>
                    {groupTour?.violationControls && (
                        <TableContainer component={Paper}>
                        <Table>
                            {/* Table Head */}
                            <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Duration (Days)</TableCell>
                            </TableRow>
                            </TableHead>

                            {/* Table Body */}
                            <TableBody>
                            {/* Row 1: Visa Required - Passport */}
                            <TableRow>
                                <TableCell>Visa Required - Passport Validity</TableCell>
                                <TableCell>
                                <Select
                                    value={groupTour?.violationControls?.visaReqPassportValidType}
                                    onChange={(e) => {
                                    const updatedViolations = {
                                        ...groupTour.violationControls,
                                        visaReqPassportValidType: e.target.value,
                                    };
                                    setGroupTour({ ...groupTour, violationControls: updatedViolations });
                                    }}
                                    fullWidth
                                >
                                    <MenuItem value="tourStartDate">Tour Start Date</MenuItem>
                                    <MenuItem value="tourEndDate">Tour End Date</MenuItem>
                                    <MenuItem value="visaApplicationDate">Visa Application Date</MenuItem>
                                </Select>
                                </TableCell>
                                <TableCell>
                                <TextField
                                    value={groupTour?.violationControls?.visaReqPassportValidDurationDays}
                                    onChange={(e) => {
                                    const updatedViolations = {
                                        ...groupTour.violationControls,
                                        visaReqPassportValidDurationDays: e.target.value,
                                    };
                                    setGroupTour({ ...groupTour, violationControls: updatedViolations });
                                    }}
                                    fullWidth
                                />
                                </TableCell>
                            </TableRow>

                            {/* Row 2: Visa Required - UAE Visa */}
                            <TableRow>
                                <TableCell>Visa Required - UAE Visa Validity</TableCell>
                                <TableCell>
                                <Select
                                    value={groupTour?.violationControls?.visaReqUAEResValidType}
                                    onChange={(e) => {
                                    const updatedViolations = {
                                        ...groupTour.violationControls,
                                        visaReqUAEResValidType: e.target.value,
                                    };
                                    setGroupTour({ ...groupTour, violationControls: updatedViolations });
                                    }}
                                    fullWidth
                                >
                                    <MenuItem value="tourStartDate">Tour Start Date</MenuItem>
                                    <MenuItem value="tourEndDate">Tour End Date</MenuItem>
                                    <MenuItem value="visaApplicationDate">Visa Application Date</MenuItem>
                                </Select>
                                </TableCell>
                                <TableCell>
                                <TextField
                                    value={groupTour?.violationControls?.visaReqUAEResValidDurationDays}
                                    onChange={(e) => {
                                    const updatedViolations = {
                                        ...groupTour.violationControls,
                                        visaReqUAEResValidDurationDays: e.target.value,
                                    };
                                    setGroupTour({ ...groupTour, violationControls: updatedViolations });
                                    }}
                                    fullWidth
                                />
                                </TableCell>
                            </TableRow>

                            {/* Row 3: No Visa Required - Passport */}
                            <TableRow>
                                <TableCell>No Visa Required - Passport Validity</TableCell>
                                <TableCell>
                                <Select
                                    value={groupTour?.violationControls?.noVisaReqPassportValidType}
                                    onChange={(e) => {
                                    const updatedViolations = {
                                        ...groupTour.violationControls,
                                        noVisaReqPassportValidType: e.target.value,
                                    };
                                    setGroupTour({ ...groupTour, violationControls: updatedViolations });
                                    }}
                                    fullWidth
                                >
                                    <MenuItem value="tourStartDate">Tour Start Date</MenuItem>
                                    <MenuItem value="tourEndDate">Tour End Date</MenuItem>
                                    <MenuItem value="visaApplicationDate">Visa Application Date</MenuItem>
                                </Select>
                                </TableCell>
                                <TableCell>
                                <TextField
                                    value={groupTour?.violationControls?.noVisaReqPassportValidDurationDays}
                                    onChange={(e) => {
                                    const updatedViolations = {
                                        ...groupTour.violationControls,
                                        noVisaReqPassportValidDurationDays: e.target.value,
                                    };
                                    setGroupTour({ ...groupTour, violationControls: updatedViolations });
                                    }}
                                    fullWidth
                                />
                                </TableCell>
                            </TableRow>

                            {/* Row 4: No Visa Required - UAE Visa */}
                            <TableRow>
                                <TableCell>No Visa Required - UAE Visa Validity</TableCell>
                                <TableCell>Tour End Date</TableCell>
                                <TableCell>1</TableCell>
                            </TableRow>
                            </TableBody>
                        </Table>
                        </TableContainer>
                    )}

                    <Grid md={12} style={{marginTop: '1rem'}}>
                        <Button 
                            className="m-3 text-center d-flex align-items-center custom-button" 
                            type='submit'
                            >
                            Save Changes
                        </Button>
                    </Grid>
                </Grid>
            </Container>
            
        </form>
    );
};

export default GroupSettings;
