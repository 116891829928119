import React, { useState, useEffect } from 'react';

const DocumentPreview = ({ base64Data, mimeType }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (base64Data) {
      // Simulate loading time for demonstration purposes
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 500); // Adjust the timeout duration as needed

      return () => clearTimeout(timer); // Clean up the timeout when component unmounts
    } else {
      setIsLoading(false);
    }
  }, [base64Data]);

  if (isLoading) {
    return <p>Loading document...</p>; // You can replace this with a spinner or loading animation
  }

  if (!base64Data) {
    return <p>No document to display.</p>;
  }

  if (mimeType === 'application/pdf') {
    // Display PDF
    return (
      <object
        data={`data:application/pdf;base64,${base64Data}`}
        type="application/pdf"
        width="100%"
        height="100%"
      >
        <embed
          width="100%"
          height="100%"
          src={`data:application/pdf;base64,${base64Data}`}
          type="application/pdf"
        />
      </object>
    );
  } else if (mimeType.startsWith('image/')) {
    // Display Image
    return (
      <img
        src={`data:${mimeType};base64,${base64Data}`}
        alt="Document Preview"
        style={{ objectFit: 'contain', maxHeight: '100%', maxWidth: '100%' }}
      />
    );
  } else {
    return <p>Unsupported file type.</p>;
  }
};

export default DocumentPreview;
