import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function CountrySelect({ countrySelectVal=[], setCountrySelectVal, fieldLabel, isSchengen, fullWidth, variant="outlined", sx={width: 300}, multiple  }) {

  const handleCountryChange = (event, value) => {
    if (multiple){
      setCountrySelectVal(value.map((val) => val.label))
    } else {
      setCountrySelectVal(value?value.label:'');
    }    
  }
  const value = multiple
    ? (isSchengen
        ? countrySelectVal.map((selectedCountry) => 
            schengen_countries.find((country) => country?.label === selectedCountry) || null
          )
        : countrySelectVal.map((selectedCountry) => 
            countries.find((country) => country?.label === selectedCountry) || null
          )
      )
    : (isSchengen
      ? schengen_countries.find((country) => country?.label === countrySelectVal) || ""
      : countries.find((country) => country?.label === countrySelectVal) || ""
  );

  return (
    <Autocomplete
      multiple={multiple}
      disablePortal
      id={fieldLabel}
      options={(isSchengen)?schengen_countries:countries}
      sx={sx}
      renderInput={(params) => <TextField fullWidth={fullWidth} variant={variant} {...params} label={fieldLabel} />}
      value={value}
      onChange={handleCountryChange} 
      getOptionLabel={(option) => option?.label}
      autoFocus
    />
  );
}

const countries = [  
  {"label": "Afghanistan", code: "AF", phone: "93"},
  {"label": "Albania", code: "AL", phone: "355"},
  {"label": "Algeria", code: "DZ", phone: "213"},
  {"label": "American Samoa", code: "AS", phone: "1684"},
  {"label": "Andorra", code: "AD", phone: "376"},
  {"label": "Angola", code: "AO", phone: "244"},
  {"label": "Anguilla", code: "AI", phone: "1264"},
  {"label": "Antarctica", code: "AQ", phone: "672"},
  {"label": "Antigua and Barbuda", code: "AG", phone: "1268"},
  {"label": "Argentina", code: "AR", phone: "54"},
  {"label": "Armenia", code: "AM", phone: "374"},
  {"label": "Aruba", code: "AW", phone: "297"},
  {"label": "Australia", code: "AU", phone: "61"},
  {"label": "Austria", code: "AT", phone: "43"},
  {"label": "Azerbaijan", code: "AZ", phone: "994"},
  {"label": "Bahamas", code: "BS", phone: "1242"},
  {"label": "Bahrain", code: "BH", phone: "973"},
  {"label": "Bangladesh", code: "BD", phone: "880"},
  {"label": "Barbados", code: "BB", phone: "1246"},
  {"label": "Belarus", code: "BY", phone: "975"},
  {"label": "Belgium", code: "BE", phone: "32"},
  {"label": "Belize", code: "BZ", phone: "501"},
  {"label": "Benin", code: "BJ", phone: "229"},
  {"label": "Bermuda", code: "BM", phone: "1441"},
  {"label": "Bhutan", code: "BT", phone: "975"},
  {"label": "Bolivia", code: "BO", phone: "591"},
  {"label": "Bosnia and Herzegovina", code: "BA", phone: "387"},
  {"label": "Botswana", code: "BW", phone: "267"},
  {"label": "Bouvet Island", code: "BV", phone: "47"},
  {"label": "Brazil", code: "BR", phone: "55"},
  {"label": "British Indian Ocean Territory", code: "IO", phone: "246"},
  {"label": "British Virgin Islands", code: "VG", phone: "1284"},
  {"label": "Brunei", code: "BN", phone: "973"},
  {"label": "Bulgaria", code: "BG", phone: "359"},
  {"label": "Burkina Faso", code: "BF", phone: "226"},
  {"label": "Burundi", code: "BI", phone: "257"},
  {"label": "Cambodia", code: "KH", phone: "855"},
  {"label": "Cameroon", code: "CM", phone: "237"},
  {"label": "Canada", code: "CA", phone: "1"},
  {"label": "Cape Verde", code: "CV", phone: "238"},
  {"label": "Caribbean Netherlands", code: "", phone: ""},
  {"label": "Cayman Islands", code: "KY", phone: "345"},
  {"label": "Central African Republic", code: "CF", phone: "236"},
  {"label": "Chad", code: "TD", phone: "235"},
  {"label": "Chile", code: "CL", phone: "56"},
  {"label": "China", code: "CN", phone: "86"},
  {"label": "Christmas Island", code: "CX", phone: "61"},
  {"label": "Colombia", code: "CO", phone: "57"},
  {"label": "Comoros", code: "KM", phone: "269"},
  {"label": "Congo", code: "CG", phone: "242"},
  {"label": "Cook Islands", code: "CK", phone: "682"},
  {"label": "Costa Rica", code: "CR", phone: "506"},
  {"label": "Croatia", code: "HR", phone: "385"},
  {"label": "Cuba", code: "CU", phone: "53"},
  {"label": "Cyprus", code: "CY", phone: "357"},
  {"label": "Czech Republic", code: "CZ", phone: "420"},
  {"label": "Democratic Republic of the Congo", code: "CD", phone: "243"},
  {"label": "Denmark", code: "DK", phone: "45"},
  {"label": "Djibouti", code: "DJ", phone: "253"},
  {"label": "Dominica", code: "DM", phone: "1767"},
  {"label": "Dominican Republic", code: "DO", phone: "1849"},
  {"label": "East Timor", code: "TL", phone: "670"},
  {"label": "Ecuador", code: "EC", phone: "593"},
  {"label": "Egypt", code: "EG", phone: "20"},
  {"label": "El Salvador", code: "SV", phone: "503"},
  {"label": "Equatorial Guinea", code: "GQ", phone: "240"},
  {"label": "Eritrea", code: "ER", phone: "291"},
  {"label": "Estonia", code: "EE", phone: "372"},
  {"label": "Ethiopia", code: "ET", phone: "251"},
  {"label": "Falkland Islands", code: "FK", phone: "500"},
  {"label": "Faroe Islands", code: "FO", phone: "298"},
  {"label": "Fiji", code: "FJ", phone: "679"},
  {"label": "Finland", code: "FI", phone: "358"},
  {"label": "France", code: "FR", phone: "33"},
  {"label": "French Guiana", code: "GF", phone: "594"},
  {"label": "French Polynesia", code: "PF", phone: "689"},
  {"label": "French Southern and Antarctic Lands", code: "TF", phone: "269"},
  {"label": "Gabon", code: "GA", phone: "241"},
  {"label": "Gambia", code: "GM", phone: "220"},
  {"label": "Georgia", code: "GE", phone: "995"},
  {"label": "Germany", code: "DE", phone: "49"},
  {"label": "Ghana", code: "GH", phone: "233"},
  {"label": "Gibraltar", code: "GI", phone: "350"},
  {"label": "Greece", code: "GR", phone: "30"},
  {"label": "Greenland", code: "GL", phone: "299"},
  {"label": "Grenada", code: "GD", phone: "1473"},
  {"label": "Guadeloupe", code: "GP", phone: "590"},
  {"label": "Guam", code: "GU", phone: "1671"},
  {"label": "Guatemala", code: "GT", phone: "502"},
  {"label": "Guernsey", code: "GG", phone: "44"},
  {"label": "Guinea", code: "", phone: ""},
  {"label": "Guyana", code: "GY", phone: "592"},
  {"label": "Haiti", code: "HT", phone: "509"},
  {"label": "Heard Island and McDonald Islands", code: "HM", phone: "672"},
  {"label": "Honduras", code: "HN", phone: "504"},
  {"label": "Hong Kong", code: "HK", phone: "852"},
  {"label": "Hungary", code: "HU", phone: "36"},
  {"label": "Iceland", code: "IS", phone: "354"},
  {"label": "India", code: "IN", phone: "91"},
  {"label": "Indonesia", code: "ID", phone: "62"},
  {"label": "Iran", code: "IR", phone: "98"},
  {"label": "Iraq", code: "IQ", phone: "964"},
  {"label": "Ireland", code: "IE", phone: "353"},
  {"label": "Isle of Man", code: "IM", phone: "44"},
  {"label": "Israel", code: "IL", phone: "972"},
  {"label": "Italy", code: "IT", phone: "39"},
  {"label": "Jamaica", code: "JM", phone: "1876"},
  {"label": "Japan", code: "JP", phone: "81"},
  {"label": "Jersey", code: "JE", phone: "44"},
  {"label": "Jordan", code: "JO", phone: "962"},
  {"label": "Kazakhstan", code: "KZ", phone: "7"},
  {"label": "Kenya", code: "KE", phone: "254"},
  {"label": "Kiribati", code: "KI", phone: "686"},
  {"label": "Kosovo", code: "XK", phone: "383"},
  {"label": "Kuwait", code: "KW", phone: "965"},
  {"label": "Kyrgyzstan", code: "KG", phone: "996"},
  {"label": "Laos", code: "956", phone: "LA"},
  {"label": "Latvia", code: "LV", phone: "371"},
  {"label": "Lebanon", code: "LB", phone: "961"},
  {"label": "Lesotho", code: "LS", phone: "266"},
  {"label": "Liberia", code: "LR", phone: "231"},
  {"label": "Libya", code: "LY", phone: "218"},
  {"label": "Liechtenstein", code: "LI", phone: "423"},
  {"label": "Lithuania", code: "LT", phone: "370"},
  {"label": "Luxembourg", code: "LU", phone: "352"},
  {"label": "Macau", code: "MO", phone: "853"},
  {"label": "Madagascar", code: "MG", phone: "261"},
  {"label": "Malawi", code: "MW", phone: "265"},
  {"label": "Malaysia", code: "MY", phone: "60"},
  {"label": "Maldives", code: "MV", phone: "960"},
  {"label": "Mali", code: "ML", phone: "223"},
  {"label": "Malta", code: "MT", phone: "356"},
  {"label": "Marshall Islands", code: "MH", phone: "692"},
  {"label": "Martinique", code: "MQ", phone: "596"},
  {"label": "Mauritania", code: "MR", phone: "222"},
  {"label": "Mauritius", code: "MU", phone: "230"},
  {"label": "Mayotte", code: "YT", phone: "262"},
  {"label": "Mexico", code: "MX", phone: "52"},
  {"label": "Micronesia", code: "FM", phone: "691"},
  {"label": "Moldova", code: "MD", phone: "373"},
  {"label": "Monaco", code: "MC", phone: "377"},
  {"label": "Mongolia", code: "MN", phone: "976"},
  {"label": "Montenegro", code: "ME", phone: "382"},
  {"label": "Montserrat", code: "MS", phone: "1664"},
  {"label": "Morocco", code: "MA", phone: "212"},
  {"label": "Mozambique", code: "MZ", phone: "258"},
  {"label": "Namibia", code: "NA", phone: "264"},
  {"label": "Nauru", code: "NR", phone: "674"},
  {"label": "Nepal", code: "NP", phone: "977"},
  {"label": "Netherlands", code: "NL", phone: "31"},
  {"label": "Netherlands Antilles", code: "AN", phone: "599"},
  {"label": "New Caledonia", code: "NC", phone: "687"},
  {"label": "New Zealand", code: "NZ", phone: "64"},
  {"label": "Nicaragua", code: "NI", phone: "505"},
  {"label": "Niger", code: "NE", phone: "227"},
  {"label": "Nigeria", code: "NG", phone: "234"},
  {"label": "Niue", code: "NU", phone: "683"},
  {"label": "Norfolk Island", code: "NF", phone: "672"},
  {"label": "North Korea", code: "KP", phone: "850"},
  {"label": "North Macedonia", code: "MK", phone: "389"},  
  {"label": "Northern Mariana Islands", code: "MP", phone: "1670"},
  {"label": "Norway", code: "NO", phone: "47"},
  {"label": "Oman", code: "OM", phone: "968"},
  {"label": "Pakistan", code: "PK", phone: "92"},
  {"label": "Palau", code: "PW", phone: "680"},
  {"label": "Palestine", code: "PS", phone: "970"},
  {"label": "Panama", code: "PA", phone: "507"},
  {"label": "Papua New Guinea", code: "PG", phone: "675"},
  {"label": "Paraguay", code: "PY", phone: "595"},
  {"label": "Peru", code: "PE", phone: "51"},
  {"label": "Philippines", code: "PH", phone: "63"},
  {"label": "Pitcairn Islands", code: "PN", phone: "64"},
  {"label": "Poland", code: "PL", phone: "48"},
  {"label": "Portugal", code: "PT", phone: "351"},
  {"label": "Puerto Rico", code: "PR", phone: "1939"},
  {"label": "Qatar", code: "QA", phone: "974"},
  {"label": "Qatar (Not E-Passport)", code: "QA", phone: "974"},
  {"label": "Romania", code: "RO", phone: "40"},
  {"label": "Russia", code: "RU", phone: "7"},
  {"label": "Rwanda", code: "RW", phone: "250"},
  {"label": "Saint Helena", code: "SH", phone: "290"},
  {"label": "Saint Kitts and Nevis", code: "KN", phone: "1869"},
  {"label": "Saint Lucia", code: "LC", phone: "1758"},
  {"label": "Saint Martin", code: "MF", phone: "590"},
  {"label": "Saint Pierre and Miquelon", code: "PM", phone: "508"},
  {"label": "Saint Vincent and the Grenadines", code: "VC", phone: "1784"},
  {"label": "Samoa", code: "WS", phone: "685"},
  {"label": "San Marino", code: "SM", phone: "378"},
  {"label": "Sao Tome and Principe", code: "ST", phone: "239"},
  {"label": "Saudi Arabia", code: "SA", phone: "966"},
  {"label": "Senegal", code: "SN", phone: "221"},
  {"label": "Serbia", code: "RS", phone: "381"},
  {"label": "Seychelles", code: "SC", phone: "248"},
  {"label": "Sierra Leone", code: "SL", phone: "232"},
  {"label": "Singapore", code: "SG", phone: "65"},
  {"label": "Sint Maarten", code: "", phone: ""},
  {"label": "Slovakia", code: "SK", phone: "421"},
  {"label": "Slovenia", code: "SI", phone: "386"},
  {"label": "Solomon Islands", code: "SB", phone: "677"},
  {"label": "Somalia", code: "SO", phone: "252"},
  {"label": "South Africa", code: "ZA", phone: "27"},
  {"label": "South Georgia and the South Sandwich Islands", code: "GS", phone: "500"},
  {"label": "South Korea", code: "KR", phone: "82"},
  {"label": "South Sudan", code: "SS", phone: "211"},
  {"label": "Spain", code: "ES", phone: "34"},
  {"label": "Sri Lanka", code: "LK", phone: "94"},
  {"label": "Sudan", code: "SD", phone: "249"},
  {"label": "Suriname", code: "SR", phone: "597"},
  {"label": "Svalbard and Jan Mayen", code: "SJ", phone: "47"},
  {"label": "Swaziland", code: "SZ", phone: "268"},
  {"label": "Sweden", code: "SE", phone: "46"},
  {"label": "Switzerland", code: "CH", phone: "41"},
  {"label": "Syria", code: "SY", phone: "963"},
  {"label": "Taiwan", code: "TW", phone: "886"},
  {"label": "Tajikistan", code: "TJ", phone: "992"},
  {"label": "Tanzania", code: "TZ", phone: "255"},
  {"label": "Thailand", code: "TH", phone: "66"},
  {"label": "Togo", code: "TG", phone: "228"},
  {"label": "Tokelau", code: "TK", phone: "690"},
  {"label": "Tonga", code: "TO", phone: "676"},
  {"label": "Trinidad and Tobago", code: "TT", phone: "1868"},
  {"label": "Tunisia", code: "TN", phone: "216"},
  {"label": "Turkey", code: "TR", phone: "90"},
  {"label": "Turkmenistan", code: "TM", phone: "993"},
  {"label": "Turks and Caicos Islands", code: "TC", phone: "1649"},
  {"label": "Tuvalu", code: "TV", phone: "688"},
  {"label": "Uganda", code: "UG", phone: "256"},
  {"label": "Ukraine", code: "UA", phone: "380"},
  {"label": "United Arab Emirates", code: "AE", phone: "971"},
  {"label": "United Arab Emirates (Not E-Passport)", code: "AE", phone: "971"},
  {"label": "United Kingdom", code: "GB", phone: "44"},
  {"label": "United States", code: "US", phone: "1"},
  {"label": "Uruguay", code: "UY", phone: "598"},
  {"label": "Uzbekistan", code: "UZ", phone: "998"},
  {"label": "Vanuatu", code: "VU", phone: "678"},
  {"label": "Vatican City", code: "VA", phone: "379"},
  {"label": "Venezuela", code: "VE", phone: "58"},
  {"label": "Vietnam", code: "VN", phone: "84"},
  {"label": "Wallis and Futuna", code: "WF", phone: "681"},
  {"label": "Yemen", code: "YE", phone: "967"},
  {"label": "Zambia", code: "ZM", phone: "260"},
  {"label": "Zimbabwe", code: "ZW", phone: "263"},
  {"label": "XXXXXX", code: "XX", phone: "XX"},
  {"label": "", code: "", phone: ""},
];

const schengen_countries = [
 {'code': 'AT', 'label': 'Austria', 'phone': '43'},
 {'code': 'BE', 'label': 'Belgium', 'phone': '32'},
 {'code': 'CZ', 'label': 'Czech Republic', 'phone': '420'},
 {'code': 'DK', 'label': 'Denmark', 'phone': '45'},
 {'code': 'EE', 'label': 'Estonia', 'phone': '372'},
 {'code': 'FI', 'label': 'Finland', 'phone': '358'},
 {'code': 'FR', 'label': 'France', 'phone': '33'},
 {'code': 'DE', 'label': 'Germany', 'phone': '49'},
 {'code': 'GR', 'label': 'Greece', 'phone': '30'},
 {'code': 'HU', 'label': 'Hungary', 'phone': '36'},
 {'code': 'IS', 'label': 'Iceland', 'phone': '354'},
 {'code': 'IT', 'label': 'Italy', 'phone': '39'},
 {'code': 'LV', 'label': 'Latvia', 'phone': '371'},
 {'code': 'LI', 'label': 'Liechtenstein', 'phone': '423'},
 {'code': 'LT', 'label': 'Lithuania', 'phone': '370'},
 {'code': 'LU', 'label': 'Luxembourg', 'phone': '352'},
 {'code': 'MT', 'label': 'Malta', 'phone': '356'},
 {'code': 'NL', 'label': 'Netherlands', 'phone': '31'},
 {'code': 'NO', 'label': 'Norway', 'phone': '47'},
 {'code': 'PL', 'label': 'Poland', 'phone': '48'},
 {'code': 'PT', 'label': 'Portugal', 'phone': '351'},
 {'code': 'SK', 'label': 'Slovakia', 'phone': '421'},
 {'code': 'SI', 'label': 'Slovenia', 'phone': '386'},
 {'code': 'ES', 'label': 'Spain', 'phone': '34'},
 {'code': 'SE', 'label': 'Sweden', 'phone': '46'},
 {'code': 'CH', 'label': 'Switzerland', 'phone': '41'}
];